export const MAIL_JS_USER_ID: string = 'LGrhkxJr4MfCi01g3'
// export const MAIL_JS_USER_ID: string = 'user_6uhWVC8HhId47OnV3vERh'
export const MAIL_JS_ACCESS_TOKEN: string = 'RjlZBARrqO3zvkudX_WCi'
// export const MAIL_JS_ACCESS_TOKEN: string = '1b42a4a435abc6b6691555da149842c9'
export const MAIL_JS_SERVICE_ID: string = 'service_ylfvgjr'
// export const MAIL_JS_SERVICE_ID: string = 'service_3kxi212'
export const MAIL_JS_TEMPLATE_ID: string = 'template_eg533vm'
// export const MAIL_JS_TEMPLATE_ID: string = 'template_355lvzs'

// export const SERVER_API: string = 'https://ttarasenkoart-server.onrender.com'
export const SERVER_API: string = 'https://api.ttarasenkoart.com/'
